<template>
  <div class="text-center error-wrapper">
    <AppToolbar title="Forbidden"></AppToolbar>

    <div>
      <img src="../../assets/generic-error.png" alt="Error" class="mb-4" />
      <h1 class="headline primary--text">Forbidden...</h1>
      <p class="subheading">
        Sorry, you do not have permission to access this application :(
        <br />
        Try to access with
        <router-link :to="{ name: 'logout' }">another user</router-link>.
      </p>
    </div>
  </div>
</template>

<script>
import AppToolbar from '@/components/AppToolbar'

export default {
  components: {
    AppToolbar
  },

  created() {
    this.$emit('toolbarTitleChange', 'Page not found')
  }
}
</script>

<style lang="scss" scoped>
.error-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 80%;
  }
}
</style>
